import { Text, Wrap, RegButton, A } from './style';
import success from './checked.png';
import { BackImg } from '../AboutCamp/style';
import holovna from '../../img/holovna.jpg';

export const Success = () => {
  return (
    <Wrap>
      {/* <BackImg src={holovna} /> */}
      <Text>
        Вітаю!!!
        <br />
        Ви успішно зареєструвались в табір
        <br />
        <br />
        Вам надіслано білет, який засвідчує реєстрацію, на пошту. Зверніть увагу,
        якщо ви не отримати білет, то напишіть нам{' '}
        <A href='mailto:ispirittime@gmail.com'>ispirittime@gmail.com</A>
      </Text>
      <RegButton to='/'>На головну</RegButton>
    </Wrap>
  );
};
