import {
  BackImg,
  Br,
  Description,
  Group,
  Image,
  ReversedGroup,
  ReverseSubTitle,
  SpeakerImage,
  SubTitle,
  Title,
  Wrap,
  Wrapper,
} from './style';
import chevoler from '../../img/chevoler.png';
import speaker from '../../img/speakers.png';
import aboutphoto from '../../img/aboutcamp2.png';
import holovna from '../../img/holovna.jpg';

export const AboutCamp = () => {
  return (
    <Wrap>
      {/* <BackImg src={holovna} /> */}
      <Title>ПРО ТАБІР</Title>
      <Wrapper>
        <Group>
          <Image src={chevoler} />
          <Description>
            {' '}
            Chevalier Panorama розташований в селі Яблуниця, на висоті близько
            1000 м над рівнем моря. Завдяки цьому з вікон готелю Ви можете
            спостерігати за найвищими точками України – Говерлою та вершинами
            Чорногірського хребта.
          </Description>
          <SubTitle>БАЗА CHEVALIER PANORAMA</SubTitle>
        </Group>
        {/* <ReversedGroup>
          <SpeakerImage src={speaker} />
          <Description>
            {' '}
            
          </Description>
          <ReverseSubTitle>МИКОЛА САВЧУК</ReverseSubTitle>
          <ReverseSubTitle>МАРК СЄРГЕЄВ</ReverseSubTitle>
          <ReverseSubTitle>ОЛЕГ БОГОМАЗ</ReverseSubTitle>
        </ReversedGroup> */}
        <Group>
          <Image src={aboutphoto} />
          <Description>
            <Br>Spirit Time - місце єдності та служіння. </Br>
            <Br>
              Ми організовуємо зимові та літні табори вже впродовж декількох
              років. Сумарно в наших таборах побувало більше 2300 унікальних
              учасників.
            </Br>
            <Br>
              Якщо коротко описати наші зимні табори це: дуже багато молоді,
              затишна атмосфера, затишне спілкування, настільні ігри, неймовірні
              краєвиди та спільне прагнення глибокого пізнання Бога. Ми з
              командою робимо все, щоб створити для вас максимально затишну
              атмосферу, щоб ви змогли відпочити духом та тілом, вирости духовно
              та знайти багато нових друзів.
            </Br>
          </Description>
          <SubTitle>SPIRIT TIME</SubTitle>
        </Group>
      </Wrapper>
    </Wrap>
  );
};
