// Styles
import {
  Cost,
  Description,
  InfoBlock,
  SubTitle,
  Title,
  Wrap,
  Wrapper,
  Date,
  Logo,
  TextBlock,
  RegButton,
  VideoLink,
  VideoText,
  VideoWrap,
  Members,
  Mem,
  Num,
  MainInformation,
  Block,
} from './style';

// Images
import calendar from '../../img/calendar.svg';
import cash from '../../img/cash.svg';
import playmobile from '../../img/play.png';
import { Dots } from '../Registration/loading';
import axios from 'axios';

export const MainPage = (props) => {
  return (
    <Wrap>
      {/* <InfoBlock>
        <TextBlock>
          <Wrapper>
            <img src={calendar} />
            <Date>27 - 30 січня</Date>
          </Wrapper>
          <Wrapper>
            <img src={cash} />
            <Cost>4000 грн</Cost>
          </Wrapper>
          <Description>* встигни зареєструватись</Description>
        </TextBlock>
      </InfoBlock> */}
      {/* <RegButton to='/registration'>ЗАРЕЄСТРУВАТИСЬ</RegButton> */}
      {/* <VideoWrap>
        <VideoLink
          onClick={() => {
            window.location.replace(
              'https://www.instagram.com/tv/CZM2FOBFMit/?utm_source=ig_web_copy_link'
            );
          }}
          src={playmobile}
          alt='video'
          className='play'
        />
        <VideoText>як це було</VideoText>
      </VideoWrap> */}

      <MainInformation>
        <Logo>
          <Title>Spirit Camp</Title>
          <SubTitle>«Мiсце твого зростання»</SubTitle>
        </Logo>
        <Block>
          <Wrapper>
            <img src={calendar} />
            <Date>21 - 24 червня</Date>
          </Wrapper>
          {/* <Wrapper>
            <img src={calendar} />
            <Date>01-04 вересня</Date>
          </Wrapper> */}
          <Wrapper>
            <img src={cash} />
            <Cost>5700 грн</Cost>
          </Wrapper>
            {/* <Mem>*аванс 1000грн</Mem> */}
          <Description>*аванс 1000 грн</Description>
          {props.loadingFirst !== true ? (
            <Num>{500 - props.firstUsers}</Num>
          ) : (
            <Dots>
              <p className='dots-flow'></p>
            </Dots>
          )}
          <Mem>*ціна буде збільшуватись</Mem>
          {/* {props.loadingSecond !== true ? (
            <Num>{250 - props.secondUsers}</Num>
          ) : (
            <Dots>
              <p className='dots-flow'></p>
            </Dots>
          )}
          <Mem>*залишилось місць на 2 заїзд</Mem> */}
        </Block>
        <RegButton to='/registration'>ЗАРЕЄСТРУВАТИСЬ</RegButton>
      </MainInformation>
    </Wrap>
  );
};
